.pfp {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.pfp .avatar-wrapper {
  margin-right: 8px;
  z-index: 2;
}

.pfp .avatar {
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.pfp a {
  text-decoration: none;
}

.pfp .username {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
}

.pfp .profile-name {
  display: flex;
  flex-direction: column;
}
