.note {
  min-height: 110px;
}

.note>.header .reply {
  font-size: 13px;
  color: var(--font-secondary-color);
}

.note>.header .reply a {
  color: var(--highlight);
}

.note>.header .reply a:hover {
  text-decoration-color: var(--highlight);
}

.note>.header>.info {
  font-size: var(--font-size);
  margin-left: 4px;
  white-space: nowrap;
  color: var(--font-secondary-color);
}

.note>.body {
  margin-top: 4px;
  margin-bottom: 24px;
  padding-left: 56px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-break: normal;
  overflow-x: hidden;
  overflow-y: visible;
}

.note>.footer {
  padding-left: 46px;
}

.note .footer .footer-reactions {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

@media (min-width: 720px) {
  .note .footer .footer-reactions {
    margin-left: 0;
  }
}

.note>.footer .ctx-menu {
  background-color: var(--note-bg);
  color: var(--font-secondary-color);
  border: 1px solid var(--font-secondary-color);
  border-radius: 16px;
  min-width: 0;
}

.note>.footer .ctx-menu li {
  display: grid;
  grid-template-columns: 2rem auto;
}

.ctx-menu .red {
  color: var(--error);
}

.note>.header img:hover, .note>.header .name>.reply:hover, .note .body:hover {
  cursor: pointer;
}

.note>.note-creator {
  margin-top: 12px;
  margin-left: 56px;
}

.reaction-pill {
  display: flex;
  flex-direction: row;
  margin: 0px 14px;
  user-select: none;
  color: var(--font-secondary-color);
  font-feature-settings: "tnum";
}

.reaction-pill .reaction-pill-number {
  margin-left: 8px;
}

.reaction-pill.reacted {
  color: var(--highlight);
}

.reaction-pill:hover {
  cursor: pointer;
}

.trash-icon {
  color: var(--error);
  margin-right: auto;
}

.note-expand .body {
  max-height: 300px;
  overflow-y: hidden;
}

.hidden-note .header {
  display: flex;
  align-items: center;
}

.card.note.hidden-note {
  min-height: unset;
}

.hidden-note button {
   max-height: 30px;
}

.expand-note {
  padding: 0 0 16px 0;
  font-weight: 400;
  color: var(--highlight);
  cursor: pointer;
}

.note.active {
  border-left: 1px solid var(--highlight);
  border-bottom-left-radius: 0;
  margin-left: -1px;
}
