.copy {
  cursor: pointer;
  align-items: center;
}

.copy .body {
    font-size: var(--font-size-small);
    color: var(--font-color);
    margin-right: 6px;
}

.copy .icon {
  margin-bottom: -4px;
}
