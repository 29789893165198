.note-creator {
    margin-bottom: 10px;
    background-color: var(--note-bg);
    border: none;
    border-radius: 10px;
    padding: 6px;
    position: relative;
}

.note-reply {
    margin: 10px;
}

.note-creator textarea {
    outline: none;
    resize: none;
    background-color: var(--note-bg);
    border-radius: 10px 10px 0 0;
    min-height: 120px;
    max-width: stretch;
    min-width: stretch;
}

.note-creator textarea::placeholder {
  color: var(--font-secondary-color);
  font-size: var(--font-size);
  line-height: 24px;
}

@media (min-width: 520px) {
  .note-creator textarea { min-height: 210px; }
}

@media (min-width: 720px) {
  .note-creator textarea { min-height: 321px; }
}

.note-creator-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.note-creator .attachment {
  cursor: pointer;
  position: absolute;
  right: 16px;
  bottom: 12px;
  width: 48px;
  height: 36px;
  background: var(--gray-dark);
  color: white;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-creator .attachment:hover {
  background: var(--font-color);
  color: var(--gray-dark);
}

.light .note-creator .attachment {
  background: var(--gray-light);
}

.light .note-creator .attachment:hover {
  background: var(--gray-dark);
  color: white;
}

.note-creator-actions button:not(:last-child) {
    margin-right: 4px;
}

.note-creator .error {
    position: absolute;
    left: 16px;
    bottom: 12px;
    font-color: var(--error);
    margin-right: 12px;
    font-size: 16px;
}

.note-creator .btn {
    border-radius: 20px;
    font-weight: bold;
    background-color: var(--bg-color);
    color: var(--font-color);
    font-size: var(--font-size);
}

.note-create-button {
  width: 48px;
  height: 48px;
  background-color: var(--highlight);
  border: none;
  border-radius: 100%;
  position: fixed;
  bottom: 50px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 520px) {
  .note-create-button {
    right: 10vw;
  }
}

@media (min-width: 1020px) {
  .note-create-button {
    right: calc(50% - 360px);
  }
}

.note-creator-modal .modal-body {
  background: var(--modal-bg-color);
}

@media (max-width: 720px) {
  .note-creator-modal {
    align-items: flex-start;
  }
  .note-creator-modal .modal-body {
    margin-top: 20vh;
  }
}

.note-preview {
  word-break: break-all;
}

.note-preview-body {
  text-overflow: ellipsis;
  padding: 4px 4px 0 56px;
  font-size: 14px;
}
